import { StoryblokVue, apiPlugin } from '@storyblok/vue';

export default defineNuxtPlugin(({ vueApp }) => {
    const {
        public: {
            storyblok,
            storyblokVersion,
        },
    } = useRuntimeConfig();

    vueApp.use(StoryblokVue, {
        ...storyblok,
        apiOptions: {
            cache: {
                clear: 'auto',
                type: 'memory',
            },
        },
        bridge: storyblokVersion === 'draft',
        use: [apiPlugin],
    });
});
