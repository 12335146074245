<!-- eslint-disable tailwindcss/no-custom-classname -->
<template>
    <div v-if="isVisible"
        class="absolute left-xs top-xs flex flex-col overflow-hidden rounded"
        :style="variables"
        :class="{
            'max-w-[60%]': isSearch,
            'max-w-[65%] lg:max-w-[90%]': !isSearch,
            // background
            'bg-[color:var(--main-background)]': isCustomMainBackgroundColor,
            'bg-white': mainTheme?.background?.color === 'WHITE',
            'bg-black': mainTheme?.background?.color === 'BLACK',
            'bg-yellow-500': mainTheme?.background?.color === 'YELLOW',
            'bg-[#EF1C35]': !mainTheme || mainTheme.background?.color === 'RED',
            // text
            'text-[color:var(--main-text)]': isCustomMainTextColor,
            'text-black': mainTheme?.text?.color === 'BLACK',
            'text-yellow-500': mainTheme?.text?.color === 'YELLOW',
            'text-[#EF1C35]': mainTheme?.text?.color === 'RED',
            'text-white': !mainTheme || mainTheme.text?.color === 'WHITE',
        }">
        <div class="flex flex-col items-center justify-center px-1.5 text-center
            font-prenton-cond uppercase leading-none tracking-2xl md:py-0.5"
            :class="{
                'md:py-2': isSearch,
                'md:py-1.5': !isSearch && !isSearchModal,
                'md:py-0': isSearchModal,
                'order-1': isStyleMirrored,
            }">
            <p v-if="promotion.style.type === 'TEXT'"
                class="py-1 leading-none md:py-0 md:leading-none"
                :style="variables"
                :class="{
                    'md:text-xs': isSearch,
                    'font-prenton-cond': mainTheme?.font?.family === 'PRENTON_COND',
                    'font-sans-narrow': mainTheme?.font?.family === 'SANS_NARROW',
                    'font-sans-caption': mainTheme?.font?.family === 'SANS_CAPTION',
                    // size
                    'text-xs md:text-sm lg:text-xs': mainTheme?.font?.size === 'EXTRA_SMALL',
                    'text-xs md:text-sm lg:text-sm': mainTheme?.font?.size === 'SMALL',
                    'text-xs md:text-sm lg:text-base': mainTheme?.font?.size === 'MEDIUM',
                    'text-xs md:text-sm lg:text-lg': mainTheme?.font?.size === 'LARGE',
                    'text-xs md:text-sm lg:text-xl': mainTheme?.font?.size === 'EXTRA_LARGE',
                    'text-[length:var(--main-font-size)]': mainTheme?.font?.size === 'CUSTOM',
                }">
                <!-- eslint-disable-next-line vue/no-v-html -->
                <span v-html="promotion.style.content?.split('|').join('<br>')"></span>
            </p>

            <p v-else-if="['PERCENT', 'PERCENT_WITH_AMOUNT', 'PERCENT_WITH_TEXT'].includes(promotion.style.type) &&
                   (promotion.discount.type !== 'CatalogPromotionFixedAmount' && discountAmount > 0)"
                class="text-lg"
                :class="{
                    'md:text-2xl': isSearch,
                    'text-2xl leading-none md:text-4xl': !isSearch,
                    'font-prenton-cond': mainTheme?.font?.family === 'PRENTON_COND',
                    'font-sans-narrow': mainTheme?.font?.family === 'SANS_NARROW',
                    'font-sans-caption': mainTheme?.font?.family === 'SANS_CAPTION',
                    // size
                    'text-xs': mainTheme?.font?.size === 'EXTRA_SMALL',
                    'text-sm': mainTheme?.font?.size === 'SMALL',
                    'text-base': mainTheme?.font?.size === 'MEDIUM',
                    'text-lg': mainTheme?.font?.size === 'LARGE',
                    'text-xl': mainTheme?.font?.size === 'EXTRA_LARGE',
                    'text-[length:var(--main-font-size)]': mainTheme?.font?.size === 'CUSTOM',
                }">
                <template v-if="promotion.discount.type === 'CatalogPromotionFixedAmount'">
                    -{{ formatCurrency(discountAmount) }}
                </template>

                <template v-else>
                    -{{ discountAmount }}%
                </template>
            </p>

            <p v-else-if="['CLUB_PERCENT_WITH_TEXT'].includes(promotion.style.type)"
                class="p-0.5 text-lg"
                :class="{
                    'md:text-2xl': isSearch,
                    'text-2xl leading-none md:text-4xl': !isSearch && !isSearchModal,
                    'font-prenton-cond': mainTheme?.font?.family === 'PRENTON_COND',
                    'font-sans-narrow': mainTheme?.font?.family === 'SANS_NARROW',
                    'font-sans-caption': mainTheme?.font?.family === 'SANS_CAPTION',
                    // size
                    'text-xs': mainTheme?.font?.size === 'EXTRA_SMALL' || isSearchModal ,
                    'text-sm': mainTheme?.font?.size === 'SMALL',
                    'text-base': mainTheme?.font?.size === 'MEDIUM',
                    'text-lg': mainTheme?.font?.size === 'LARGE',
                    'text-xl': mainTheme?.font?.size === 'EXTRA_LARGE',
                    'text-[length:var(--main-font-size)]': mainTheme?.font?.size === 'CUSTOM',
                }">
                <template v-if="promotion.discount.type === 'CatalogPromotionFixedAmount'">
                    -{{ formatCurrency(discountAmount) }}
                </template>

                <template v-else>
                    -{{ clubDiscountAmount }}%
                </template>
            </p>
        </div>

        <p v-if="promotion.style.type === 'PERCENT_WITH_AMOUNT' && savedAmount > 0 && !isSearch"
            class="px-1 text-center font-sans text-xs font-bold leading-[16px] md:px-2 md:leading-[22px]"
            :class="{
                'hidden md:block md:h-6 md:px-2 md:text-[16px]': isSearch,
                // background
                'bg-[color:var(--main-background)]': isCustomAltTextColor,
                'bg-white': altTheme?.background?.color === 'WHITE',
                'bg-black': altTheme?.background?.color === 'BLACK',
                'bg-yellow-500': altTheme?.background?.color === 'YELLOW',
                'bg-orange-500': altTheme?.background?.color === 'ORANGE',
                'bg-[#EF1C35]': !altTheme || altTheme.background?.color === 'RED',
                // text
                'text-[color:var(--alt-text)]': isCustomAltTextColor,
                'text-black': altTheme?.text?.color === 'BLACK',
                'text-yellow-500': altTheme?.text?.color === 'YELLOW',
                'text-orange-500': altTheme?.text?.color === 'ORANGE',
                'text-[#EF1C35]': altTheme?.text?.color === 'RED',
                'text-white': !altTheme || altTheme.text?.color === 'WHITE',
                //font
                'font-prenton-cond': altTheme?.font?.family === 'PRENTON_COND',
                'font-sans-narrow': altTheme?.font?.family === 'SANS_NARROW',
                'font-sans-caption': altTheme?.font?.family === 'SANS_CAPTION',
                // size
                'text-xs md:text-sm lg:text-xs': altTheme?.font?.size === 'EXTRA_SMALL',
                'text-xs md:text-sm lg:text-sm': altTheme?.font?.size === 'SMALL',
                'text-xs md:text-sm lg:text-base': altTheme?.font?.size === 'MEDIUM',
                'text-xs md:text-sm lg:text-lg': altTheme?.font?.size === 'LARGE',
                'text-xs md:text-sm lg:text-xl': altTheme?.font?.size === 'EXTRA_LARGE',
                'text-[length:var(--main-font-size)]': altTheme?.font?.size === 'CUSTOM',
                // mirrored
                'order-1': isStyleMirrored,
            }">
            {{ $t('discount.save', { amount: formatCurrency(savedAmount) }) }}
        </p>

        <p v-else-if="promotion.style.type === 'PERCENT_WITH_TEXT'"
            class="hidden p-1 text-center font-sans font-bold md:block md:px-2"
            :class="{
                // background
                'bg-[color:var(--alt-background)]': isCustomAltBackgroundColor,
                'bg-white': altTheme?.background?.color === 'WHITE',
                'bg-black': altTheme?.background?.color === 'BLACK',
                'bg-yellow-500': altTheme?.background?.color === 'YELLOW',
                'bg-orange-500': altTheme?.background?.color === 'ORANGE',
                'bg-[#EF1C35]': !altTheme || altTheme.background?.color === 'RED',
                // text
                'text-[color:var(--alt-text)]': isCustomAltTextColor,
                'text-black': altTheme?.text?.color === 'BLACK',
                'text-yellow-500': altTheme?.text?.color === 'YELLOW',
                'text-orange-500': altTheme?.text?.color === 'ORANGE',
                'text-[#EF1C35]': altTheme?.text?.color === 'RED',
                'text-white': !altTheme || altTheme.text?.color === 'WHITE',
                //font
                'font-prenton-cond': altTheme?.font?.family === 'PRENTON_COND',
                'font-sans-narrow': altTheme?.font?.family === 'SANS_NARROW',
                'font-sans-caption': altTheme?.font?.family === 'SANS_CAPTION',
                // size
                'text-xs lg:text-xs': altTheme?.font?.size === 'EXTRA_SMALL',
                'text-xs lg:text-sm': altTheme?.font?.size === 'SMALL',
                'text-xs lg:text-base': altTheme?.font?.size === 'MEDIUM',
                'text-xs lg:text-lg': altTheme?.font?.size === 'LARGE',
                'text-xs lg:text-xl': altTheme?.font?.size === 'EXTRA_LARGE',
                'text-[length:var(--alt-font-size)]': altTheme?.font?.size === 'CUSTOM' && !isSearch,
                'px-1 text-sm': isSearchModal
            }">
            {{ promotion.style.altContent }}
        </p>

        <p v-if="clubDiscountAmount && clubDiscountAmount > 0 && promotion.style.type === 'CLUB_PERCENT_WITH_TEXT'"
            class="px-1 text-center font-sans font-bold md:px-2"
            :class="{
                // background
                'bg-[color:var(--alt-background)]': isCustomAltBackgroundColor,
                'bg-white': altTheme?.background?.color === 'WHITE',
                'bg-black': altTheme?.background?.color === 'BLACK',
                'bg-yellow-500': altTheme?.background?.color === 'YELLOW',
                'bg-orange-500': altTheme?.background?.color === 'ORANGE',
                'bg-[#EF1C35]': !altTheme || altTheme.background?.color === 'RED',
                // text
                'text-[color:var(--alt-text)]': isCustomAltTextColor,
                'text-black': altTheme?.text?.color === 'BLACK',
                'text-yellow-500': altTheme?.text?.color === 'YELLOW',
                'text-orange-500': altTheme?.text?.color === 'ORANGE',
                'text-[#EF1C35]': altTheme?.text?.color === 'RED',
                'text-white': !altTheme || altTheme.text?.color === 'WHITE',
                //font
                'font-prenton-cond': altTheme?.font?.family === 'PRENTON_COND',
                'font-sans-narrow': altTheme?.font?.family === 'SANS_NARROW',
                'font-sans-caption': altTheme?.font?.family === 'SANS_CAPTION',
                // size
                'text-xs md:text-sm lg:text-xs': altTheme?.font?.size === 'EXTRA_SMALL',
                'text-xs md:text-sm lg:text-sm': altTheme?.font?.size === 'SMALL',
                'text-xs md:text-sm lg:text-base': altTheme?.font?.size === 'MEDIUM',
                'text-xs md:text-sm lg:text-lg': altTheme?.font?.size === 'LARGE',
                'text-xs md:text-sm lg:text-xl': altTheme?.font?.size === 'EXTRA_LARGE',
                'text-[length:var(--alt-font-size)]': altTheme?.font?.size === 'CUSTOM' && !isSearch,
                'p-1 text-sm': isSearchModal
            }">
            {{ promotion.style.altContent }}
        </p>
    </div>
</template>

<script setup lang="ts">
import type { PromotionData } from '~~/types';

const properties = defineProps<{
    promotion: PromotionData
    price: number
    originalPrice: number
    clubPrice?: number
    isSearch?: boolean
    isSearchModal?: boolean
}>();

const { format: formatCurrency } = useCurrency();

const discountAmount = computed(() => {
    if (properties.promotion.discount.type === 'CatalogPromotionProductPromotionPrice') {
        return Math.round((1 - (properties.price / properties.originalPrice)) * 100);
    }

    return properties.promotion.discount.amount ?? undefined;
});

const clubDiscountAmount = computed<number | undefined>(() => {
    if (properties.promotion.discount.type === 'CatalogPromotionPercent' && properties.clubPrice) {
        return Number.parseFloat(
            (((properties.originalPrice - properties.clubPrice) / properties.originalPrice) * 100).toFixed(0),
        );
    }

    return undefined;
});

const savedAmount = computed(() => properties.originalPrice - properties.price);

const isVisible = computed(() => (
    (properties.promotion.style.type !== 'TEXT'
    && discountAmount.value > 0)
    || (
        properties.promotion.style.type === 'TEXT'
        && properties.promotion.style.content !== null
    )
    || (
        properties.promotion.style.type === 'CLUB_PERCENT_WITH_TEXT'
        && properties.clubPrice !== undefined
        && properties.clubPrice > 0
    )
));

const mainTheme = computed(() => properties.promotion.style.mainTheme);
const altTheme = computed(() => properties.promotion.style.altTheme);

const isStyleMirrored = computed(() => properties.promotion.style?.mirrored);

// Custom Main
const isCustomMainBackgroundColor = computed(() => mainTheme.value?.background?.color === 'CUSTOM');
const isCustomMainTextColor = computed(() => mainTheme.value?.text?.color === 'CUSTOM');
const mainFontSize = computed(() => mainTheme.value?.font?.size);
const mainFontFamily = computed(() => mainTheme.value?.font?.family);
const isCustomMainFontSize = computed(() => mainTheme.value?.font?.size === 'CUSTOM');

// Custom Alt
const isCustomAltBackgroundColor = computed(() => altTheme.value?.background?.color === 'CUSTOM');
const isCustomAltTextColor = computed(() => altTheme.value?.text?.color === 'CUSTOM');
const isCustomAltFontSize = computed(() => altTheme.value?.font?.size === 'CUSTOM');

const fontFamily = ref('');

const variables = computed(() => ({
    /* eslint-disable @typescript-eslint/naming-convention */
    ...isCustomMainBackgroundColor ? {
        '--main-background': mainTheme.value?.background?.customColor ?? undefined,
    } : {},
    ...isCustomMainTextColor ? {
        '--main-text': mainTheme.value?.text?.customColor ?? undefined,
    } : {},
    ...isCustomAltBackgroundColor ? {
        '--alt-background': altTheme.value?.background?.customColor ?? undefined,
    } : {},
    ...isCustomAltTextColor ? {
        '--alt-text': altTheme.value?.text?.customColor ?? undefined,
    } : {},
    ...mainFontFamily ? {
        '--font-family': fontFamily.value ?? undefined,
    } : {},
    ...mainFontSize ? {
        '--font-size': mainTheme.value?.font?.size ? `${mainTheme.value?.font?.size}` : undefined,
    } : {},
    ...isCustomMainFontSize ? {
        '--main-font-size': mainTheme.value?.font?.customSize ? `${mainTheme.value?.font?.customSize}px` : undefined,
    } : {},
    ...isCustomAltFontSize ? {
        '--alt-font-size': altTheme.value?.font?.customSize ? `${altTheme.value?.font?.customSize}px` : undefined,
    } : {},
    /* eslint-enable @typescript-eslint/naming-convention */
}));
</script>
