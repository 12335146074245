import type { ISbStoriesParams, ISbStoryData } from 'storyblok-js-client';
import { type MaybeRef } from '@vueuse/core';
import { pick } from 'es-toolkit';

// NOTE: Also see useAsyncStoryblokStories

type ReffedParameters = {
    [K in keyof ISbStoriesParams]: MaybeRef<ISbStoriesParams[K]>
};

export function useStoryblokStoriesData(fetchKey: string, parameters: ReffedParameters) {
    const api = useStoryblokApi();
    const { transform } = useStoryblokTransformer();

    const watchValues = Object.values(parameters).filter((value) => isRef(value));

    return useAsyncData(
        fetchKey,
        async () => {
            const concreteParameters: ISbStoriesParams = {};
            for (const [key, value] of Object.entries(parameters)) {
                concreteParameters[key as keyof ISbStoriesParams] = unref(value);
            }

            return api.getStories({
                // eslint-disable-next-line @typescript-eslint/naming-convention
                ...concreteParameters,
                version: useRuntimeConfig().public.storyblokVersion === 'published' ? 'published' : 'draft',
            });
        },
        {
            watch: watchValues,
            transform: (response) => ({
                ...pick(response, [
                    'total',
                ]),
                stories: response.data.stories.map((story) => ({
                    ...pick(story, [
                        'name',
                        'id',
                        'uuid',
                        'slug',
                        'full_slug',
                        'position',
                    ]),
                    content: transform(story.content),
                })) as ISbStoryData[],
            }),
        },
    );
}
