import { trim } from 'es-toolkit';

export function useSbUrl() {
    return {
        format: (url?: string): string => {
            if (!url || url.length === 0) {
                return '#';
            }

            if (/^https?:\/\//.test(url)) {
                return url;
            }

            return `/${trim(url, '/')}`;
        },
    };
}
